import React from "react"
import { MapPin } from "react-feather"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Interviews = ({ children }) => {
  return (
    <div className="jobs-container">
      <AniLink
        fade
        to="/interviews/urbandine"
        className="interview-link"
        duration={0.4}
      >
        <div className="interview-container">
          <div className="job-header">
            Jaikishen Jayesingha and Bhagya Goonewardhane: Founded Urbandine in
            the Netherlands
            {/* <div className="item-arrow">
              →
              </div> */}
          </div>
          <div className="interview-description">
            <p>
              We spoke to Jay and Bhagya of Urbandine, originally immigrants
              from Sri Lanka about their experience starting a company in the
              Netherlands.
            </p>
          </div>
          <div className="interview-details">
            <div className="left-details">
              <div className="interview-country">🇳🇱 the Netherlands</div>
              <div className="job-tags">
                <div className="job-tag">
                  <div className="job-tag-text">FOUNDERS</div>
                </div>
              </div>
            </div>
            <div className="right-details">
              <div className="job-item">
                <div>
                  <MapPin className="job-icon" />
                </div>
                Amsterdam, Netherlands
              </div>
            </div>
          </div>
        </div>
      </AniLink>

      <AniLink
        fade
        to="/interviews/evarvest"
        className="interview-link"
        duration={0.4}
      >
        <div className="interview-container">
          <div className="job-header">
            Stephanie Brennan: Founder of Evarvest
          </div>
          <div className="interview-description">
            <p>
              We spoke to Stephanie, Founder and CEO of Evarvest, an investing
              company to understand what it is like to run a fintech startup in
              serving customers in Europe and globally, and how Lithuania's
              regulatory landscape made it a compelling choice for her to
              immigrate from Australia.
            </p>
            <div className="interview-details">
              <div className="left-details">
                <div className="interview-country">🇱🇹 Lithuania</div>
                <div className="job-tags">
                  <div className="job-tag">
                    <div className="job-tag-text">FOUNDER</div>
                  </div>
                </div>
              </div>
              <div className="right-details">
                <div className="job-item">
                  <div>
                    <MapPin className="job-icon" />
                  </div>
                  Vilnius, Lithuania
                </div>
              </div>
            </div>
          </div>
        </div>
      </AniLink>

      <AniLink
        fade
        to="/interviews/estonia"
        className="interview-link"
        duration={0.4}
      >
        <div className="interview-container">
          <div className="job-header">
            Merilin Lukk: Startup Estonia Program Manager
            {/* <div className="item-arrow">
              →
              </div> */}
          </div>
          <div className="interview-description">
            <p>
              We spoke to Merilin Lukk, Program Manager of the Estonian Startup
              Visa Program to get a full picture of their program and how it
              helps Estonia and founders build lasting value and what
              considerations founders should make before applying to the
              program.
            </p>
          </div>
          <div className="interview-details">
            <div className="left-details">
              <div className="interview-country">🇪🇪 Estonia</div>
              <div className="job-tags">
                <div className="job-tag">
                  <div className="job-tag-text">PROGRAM MANAGER</div>
                </div>
              </div>
            </div>
            <div className="right-details">
              <div className="job-item">
                <div>
                  <MapPin className="job-icon" />
                </div>
                Tallinn, Estonia
              </div>
            </div>
          </div>
        </div>
      </AniLink>

      {/* <AniLink fade to="/interviews/unsplash">
        <div className="interview-container">
            
            <div className="job-header">
            Michael Cho: Unsplash Founder
              <div className="item-arrow">
              →
              </div>
            </div>
            <div className="job-description">
              <p>We spoke with Michael to understand.</p>
            </div>
            <div className="interview-details">
              <div className="left-details">
              <div className="interview-country">
              🇨🇦  Canada
              </div>
              <div className="job-tags">
                <div className="job-tag">
                  <div className="job-tag-text">
                    FOUNDER
                  </div>
                </div>
                </div>
                
              </div>
              <div className="right-details">
                <div className="job-item">
                  <div>
                    <MapPin className="job-icon"/>
                  </div>
                  Montreal, Canada
                </div>
              </div>
            </div>

        </div>
        </AniLink> */}
    </div>
  )
}

export default Interviews
