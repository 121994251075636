import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Send, Mail, ArrowRight } from "react-feather"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../../components/worldmap"
import Interviews from "../../components/interviews"

export default class InterviewsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEcosystemsLink: false,
      showNextLink: false,
      showPrevLink: false,
    }

    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    // Esc key press
    if (event.keyCode === 27) {
      this.setState({
        showEcosystemsLink: true,
      })

      setTimeout(() => {
        let linkRef = document.getElementById("esc-action")
        linkRef.click()
      }, 50)
    }

    // Left Arrow key press
    if (event.keyCode === 37) {
      this.setState({
        showPrevLink: true,
      })

      setTimeout(() => {
        let prevLinkRef = document.getElementById("prev-action")
        prevLinkRef.click()
      }, 50)
    }

    // Right Arrow key press
    if (event.keyCode === 39) {
      this.setState({
        showNextLink: true,
      })

      setTimeout(() => {
        let nextLinkRef = document.getElementById("next-action")
        nextLinkRef.click()
      }, 50)
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    return (
      <Layout>
        {this.state.showEcosystemsLink && (
          <AniLink id="esc-action" fade to="/" duration={0.4}></AniLink>
        )}
        {this.state.showPrevLink && (
          <AniLink
            id="prev-action"
            fade
            to="/interviews/estonia/"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showNextLink && (
          <AniLink
            id="next-action"
            fade
            to="/interviews/evarvest/"
            duration={0.4}
          ></AniLink>
        )}
        <SEO
          title="Interviews with Founders and Startup Program Managers"
          description="Read interviews with founders who have immigrated to other countries to start their companies and hear from program managers in those given countries."
        />

        <div id="landing-top">
          <div id="contains-map">
            <div id="map-container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
          <Mic className="page-icon" />
        </div> */}
              <h1 id="interviews-header">Read their Stories</h1>
              <h2 className="community-description">
                Read the stories of immigrant founders that managed to complete
                the move to where they needed to be to build their companies and
                chats with ecosystem managers.
              </h2>
            </div>
          </div>
          <div id="right-col">
            <OutboundLink
              href="mailto:ziyad@immigrantfounders.com"
              target="_blank"
            >
              <div className="lp-item-container">
                <div className="lp-item-header">
                  <div className="item-icon-container">
                    <Mail className="header-icon" />
                  </div>
                  Share your story
                  <div className="item-arrow">
                    <ArrowRight />
                  </div>
                </div>
                <div className="item-description">
                  <p>
                    Join our community of founders building startups on Telegram
                    to explore your incorporation and immigration options.
                  </p>
                </div>
              </div>
            </OutboundLink>
            {/* <OutboundLink href="https://join.slack.com/t/immigrants-in-tech/shared_invite/enQtODEyMDkwNDg2Nzg4LTg0NDEzOTc0MDFlOTA3YWQ1NDU3YzE3ZjRjN2I0Mzc2NzBkMzYwNzhhOTNjZGRlNjNjMGVjM2U1YmJmOWQ2MmI" target="_blank">
        <div className="long-container">
          
          <div className="item-header">
          <div className="long-icon-container">
              <Slack className="slack-icon"/>
          </div>
          Join us on Slack
            <div className="item-arrow">
              →
            </div>
          </div>
          <div className="item-description">
          </div>

        </div>
      </OutboundLink> */}
          </div>
        </div>
        <Interviews />
      </Layout>
    )
  }
}
